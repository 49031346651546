import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonFunctionService } from 'src/app/services/commonfunction.service';
import { WebService } from 'src/app/services/Webservice.service';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {
  ForgotForm: FormGroup;
  submitted: boolean;
  Forgotvalid :boolean
  constructor(private formbuilder: FormBuilder,
    private router: Router,
    private service: WebService,
    private CommonService: CommonFunctionService) { }

  ngOnInit() {
    this.ForgotForm = this.formbuilder.group({
      email:  [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^[\w-\.\']{1,}\@([\da-zA-Z-]{1,}\.){1,}[\da-zA-Z-]{2,3}$/
          ),
        ]),
      ],
    })
  }

  submit() {
  //   this.submitted = true;
  //   if (!this.ForgotForm.invalid) {
  //     this.service.ForgotPassword(this.ForgotForm.get('email').value).then((res) => {
  //       if (res) {
  //         this.CommonService.showSuccess("Reset link send successfully to mail", "Success");
  //         this.Forgotvalid = false;
  //         this.router.navigateByUrl("login");
  //       } else {
  //         this.Forgotvalid = false;
  //         this.CommonService.showError("Invalid email id", "Error");
  //       }
  //     });
  //   }
  //   else {
  //     this.Forgotvalid = false;
  //     this.CommonService.showError("Please enter all fields", "Error");
  //    }



}
}
