import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CommonFunctionService } from 'src/app/services/commonfunction.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
  languagearr = [
    { title: 'En',Langauge: 'English', languagecode: 'en' },
    {title: 'తె', Langauge: 'తెలుగు', languagecode: 'te' },
    {title: 'हिं', Langauge: 'हिंदी', languagecode: 'hi' },
  ];
  @Output() toggleSidebar = new EventEmitter<void>();
  public config: PerfectScrollbarConfigInterface = {};
  public showSearch = false;
  public user: any;
  User_details: any;
  constructor(
    private CommonService: CommonFunctionService,
    public translate: TranslateService
  ) {
    this.User_details=JSON.parse(localStorage.getItem("User_details"));
    // const TKN = localStorage.getItem(this.CommonService.tokenname);
    // if (TKN && TKN !== null) {
    //   this.user = JSON.parse(this.CommonService.Decrypt(TKN, this.CommonService.tokenname));
    // }
  }
  ngAfterViewInit() { }
  LogOut() {
    this.CommonService.GotoURL('/');
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
    }, 100);
  }
    language(data:any){
      this.CommonService.setLanguage(data);
   }
 }
