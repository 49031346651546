import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { map } from "rxjs/internal/operators/map";
import { tap } from "rxjs/internal/operators/tap";
import { environment } from "src/environments/environment";
import { CommonFunctionService } from "./commonfunction.service";
@Injectable({
  providedIn: "root",
})
export class WebService {
  serviceurl = environment.serviceUrl;
  constructor(
    private toastr: ToastrService,
    public _httpClient: HttpClient,
    private router: Router,
    private CommonService: CommonFunctionService,
    @Inject(PLATFORM_ID) platformId: string
  ) { }

  public async Ezee_user_list(body:any): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceurl}/Ezee_Users/filter?andOr=AND&page=1&itemsPerPage=100`;
        return this._httpClient.post<any>(url,body).pipe(
          map((d: any) => (d.code == "1") ? d.document.records : false)
        ).subscribe((r: any) => resolve(r), reject);
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async Token(): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        const url = `${this.serviceurl}/Token`;
        const body = {
          "username": "Sympl",
          "password": "Sympl159"
        };
        return this._httpClient.post<any>(url, body)
          .pipe(
            map(d => (d.code === 1) ? d.document.accessToken : false))
          .subscribe((r: any) => resolve(r), reject);
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }
}

