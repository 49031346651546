import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonFunctionService } from 'src/app/services/commonfunction.service';
import { WebService } from 'src/app/services/Webservice.service';


@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  ResetForm: FormGroup;
  submitted: boolean;
  Resetvalid :boolean;
  chide = true;
  hide = true;
  param1 : any
  constructor(private formbuilder: FormBuilder,
    private router: Router,
    private service: WebService,
    private CommonService: CommonFunctionService) { }

  ngOnInit() {
    const url: any = document.URL.split('?');
  this.param1=   (url[1]? url[1].replace(/=/g, ''):  this.router.navigateByUrl("/"));
    this.ResetForm = this.formbuilder.group({
      password: ["", [Validators.required]],
      CFpassword: ["", [Validators.required]],
    })
  }

  submit() {
  //   this.submitted = true;
  //   if (!this.ResetForm.invalid) {
  //     let data =this.ResetForm.value
  //     if(data.password === data.CFpassword ){
  //       const url =`${this.service.serviceurl}/Registration/ForgotPassword?email=${this.param1}&newpwd=${data.password}`
  //       this.service.ResetPassword(url).then((res) => {
  //         if (res) {
  //           this.CommonService.showSuccess("Password changed successfully", "Success");
  //           this.Resetvalid = false;
  //           this.router.navigateByUrl("login");
  //         } else {
  //           this.Resetvalid = false;
  //           this.CommonService.showError("Oops something went wrong", "Error");
  //         }
  //       });
  //     } else {
  //       this.Resetvalid = false;
  //       this.CommonService.showError("Password and confirm password not matched", "Error");
  //     }

  //   }
  //   else {
  //     this.Resetvalid = false;
  //     this.CommonService.showError("Please enter all fields", "Error");
  //    }

   }

}
